<template>
  <el-dialog
    id="ChangePasswordDialog"
    :title="title"
    :visible.sync="dialogShow"
    center
    modal
    top="5vh"
    :close-on-click-modal="false"
    width="360px"
    close-on-press-escape
    append-to-body
    class="my-dialog abow_dialog"
    @opened="onShow"
    destroy-on-close
  >
    <el-form ref="ChangePasswordForm" :model="model" :rules="rules" @submit.native.prevent="onSubmit" v-loading="loading">
      <el-form-item prop="currentPassword" :label="$t('Попередній пароль')">
        <el-input type="password" v-model="model.currentPassword" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item prop="newPassword" :label="$t('Новий пароль')">
        <el-input type="password" v-model="model.newPassword" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item prop="newPasswordConfirm" :label="$t('Підтвердження')">
        <el-input type="password" v-model="model.newPasswordConfirm" autocomplete="off"></el-input>
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button native-type="submit" type="success" @click="onCommit" class="apply-button">{{ $t("Застосувати") }}</el-button>
      <el-button type="text" @click="dialogShow = false" class="cancel-button">{{ $t("Відмінити") }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import { bus } from "@/main";
  import { _ } from "vue-underscore";

  export default {
    name: "ChangePassword",
    components: {},
    data() {
      var that = this;
      var defaultValidateRule = { required: true, message: $tt("Це поле є необхідним") };
      var checkPassword = (rule, value, callback) => {
        if (value.length < 4) {
          callback(new Error($tt("Пароль має бути довший 4 символів")));
        } else {
          callback();
        }
      };
      var checkPasswordConfirm = (rule, value, callback) => {
        if (value && that.model.newPassword != value) {
          callback(new Error($tt("Пароль не збігається з підтвердженням")));
        } else {
          callback();
        }
      };
      return {
        loading: false,
        dialogShow: false,
        title: "Мій профіль",
        operation: null,
        model: {},
        initModel: {},
        rules: {
          currentPassword: [defaultValidateRule, { validator: checkPassword }],
          newPassword: [defaultValidateRule, { validator: checkPassword }],
          newPasswordConfirm: [defaultValidateRule, { validator: checkPasswordConfirm }],
        },
      };
    },

    methods: {
      onCommit() {
        this.$refs.ChangePasswordForm.validate((valid) => {
          if (valid) {
            var loading = this.$loading({ target: "#ChangePasswordDialog>div", text: $tt("Будь ласка, зачекайте...") });
            this.$store
              .dispatch("main/setPassword", { currentPassword: this.model.currentPassword, newPassword: this.model.newPassword })
              .finally(() => {
                loading && loading.close();
              })
              .then(() => {
                this.$message.success($tt("Пароль змінено!"));
                this.$emit("commit");
              })
              .catch((err) => {
                globalErrorMessage($tt("Помилка зміни пароля!"), err);
              });
          } else {
            this.$message.error($tt("Перевірте введені дані!"));
          }
          return false;
        });
      },
      onShow() {
        this.$refs.ChangePasswordForm.resetFields();
        this.model = _.extend({ currentPassword: "", newPassword: "", newPasswordConfirm: "" }, this.initModel);
        this.loading = false;
      },
    },
    created() {
      bus.$on("forms.password.change.show", (data) => {
        this.loading = true;
        this.title = (data && data.title) || this.title;
        this.initModel = _.extend({}, data ? data.initModel : {});
        this.dialogShow = true;
      });
      bus.$on("forms.password.change.hide", () => {
        this.dialogShow = false;
      });
    },
    computed: {},
  };
</script>
<style lang="scss">
  #ChangePasswordDialog {
    .el-dialog {
      max-height: 380px !important;
    }
  }
</style>
